import React from "react";
import { StyledHeader } from "./styledComponents";
import { useSpring, animated } from "@react-spring/web";
import { useInView } from "react-intersection-observer";

export default function Heading({ className, children, id }) {
	const [ref, inView] = useInView({
		triggerOnce: true,
		threshold: 0.7,
	});

	const headingSpring = useSpring({
		opacity: inView ? 1 : 0,
		transform: inView ? "translateX(0)" : "translateX(-100px)",
		from: { opacity: 0, transform: "translateX(-100px)" },
	});

	return (
		<StyledHeader ref={ref} id={id}>
			<animated.h1 style={headingSpring} className={className}>
				{children}
			</animated.h1>
		</StyledHeader>
	);
}
