export const light = {
	backgroundColor: "#53c6ff",

	// nav
	navbarBlurredBackground: "#0067c410",
	navBackground: "#0069c430",
	navLinkColor: "#001c36",
	contactButtonBackground:
		"radial-gradient(at 75% 75%, rgba(0, 140, 255, 0.5) 0,#58b5fdff 100%)",
	contactButtonBoxShadow: "0 0 1.25rem 0.25rem rgba(0, 0, 0, 0.1)",
	contactButtonTextColor: "#001c36",

	// mobile nav
	mobileNavBackground: "#4da3dcde",
	mobileNavLink: "#001c36",

	// arrow
	arrow: "#001c36",

	// body
	bodyBackground:
		"radial-gradient(at 11% 35%,hsla(212, 100%, 53%, 0.24) 0,hsla(338, 92%, 59%, 0.137) 50%),radial-gradient(at 84% 27%, rgba(0, 132, 255, 0.288) 0, hsla(118, 91%, 59%, 0.062) 50% ), radial-gradient(   at 82% 75%,rgba(28, 81, 255, 0.219) 0, hsla(0, 87%, 64%, 0.068) 50%)",

	// hero
	heroTextColor: "#001c36",
	heroEmphasisColor: "#0067c4",

	// heading
	headingTextColor: "#002e58",

	// story
	storyTextColor: "#001c36",

	// experience
	cardIconColor: "#e2f1ff",
	cardIconBackground: "#002e58",
	cardTextColor: "#001c36",
	cardDateColor: "#777777",
	cardDescriptionColor: "#4b4b4b",
	cardTitleColor: "#202020",
	experienceCardBackground: "#e2f1ff",

	// toolbox
	toolboxTextColor: "#001c36",
	skillImageBoxShadow: "0 0 25px rgba(0, 0, 0, 0.2)",

	// divider
	dividerColor: "#d4eaff",

	// footer
	footerTextColor: "#001c36",
	contactFormInputBackground: "#ffffff49",
	contactFormInputTextColor: "#001c36",
	contactFormInputBoxShadow: "none",
	contactFormInputBoxShadowFocused: "0 0 0.5rem rgba(0, 0, 0, 0.2)",
	contactFormInputOutlineFocused: "1.5px solid #00aaff",
	footerLogo: "#001c36",
	footerIcon: "#001c36",
	footerCredits: "#001c36",
	footerDevelopment: "#b97900",

	// button
	buttonBackground:
		"radial-gradient(at 75% 75%,rgb(0, 140, 255, 0.5) 0,rgba(0, 19, 34, 1) 100%)",
	buttonBoxShadow: "0 0 1.25rem 0.25rem rgba(0, 0, 0, 0.1)",
	buttonTextColor: "#001c36",

	// gallery
	galleryHeading: "#001c36",

	// theme switcher
	themeSwitcherBackground: "#58b5fdff",

	backToTopBackground:
		"radial-gradient(at 75% 75%,rgb(0, 140, 255, 0.5) 0,rgba(0, 19, 34, 1) 100%)",
	backToTopIcon: "#ffffff",

	// skill icons
	iconGradientStop: "#001c36",
	iconGradientStopOffset: "#001c36",

	// logo
	logoMainColor: "#002e58",
	logoAccentColor: "#002e58",
};
