import React from "react";
import { useTheme } from "styled-components";

const AI = ({ className }) => {
	const theme = useTheme();

	return (
		<svg
			width="51"
			height="49"
			viewBox="0 0 51 49"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<g clipPath="url(#clip0_0_3)">
				<path
					d="M22.44 21.73C22.23 21.08 22.04 20.46 21.84 19.81C21.63 19.16 21.44 18.56 21.28 17.96C21.11 17.38 20.97 16.84 20.82 16.34H20.78C20.59 17.24 20.36 18.13 20.07 19.03C19.76 20.03 19.45 21.07 19.11 22.11C18.82 23.17 18.51 24.15 18.19 25.03H23.48C23.36 24.59 23.19 24.07 23 23.53C22.81 22.97 22.62 22.37 22.44 21.74V21.73ZM41.65 0H9.35C4.46 0 0.5 3.96 0.5 8.85V39.89C0.5 44.79 4.46 48.74 9.35 48.74H41.65C46.55 48.74 50.5 44.78 50.5 39.89V8.85C50.5 3.95 46.54 0 41.65 0ZM31.13 34.44H26.77C26.63 34.46 26.48 34.36 26.44 34.21L24.73 29.25H16.98L15.4 34.15C15.36 34.34 15.19 34.46 15 34.44H11.08C10.85 34.44 10.79 34.31 10.85 34.06L17.56 14.75C17.62 14.54 17.68 14.31 17.77 14.06C17.85 13.62 17.89 13.16 17.89 12.71C17.87 12.61 17.95 12.5 18.06 12.48H23.46C23.63 12.48 23.71 12.54 23.73 12.65L31.33 34.11C31.39 34.34 31.33 34.44 31.12 34.44H31.13ZM38.21 34.13C38.21 34.36 38.13 34.46 37.94 34.46H33.85C33.64 34.46 33.54 34.33 33.54 34.13V18.08C33.54 17.87 33.63 17.79 33.81 17.79H37.93C38.12 17.79 38.2 17.89 38.2 18.08V34.12L38.21 34.13ZM37.77 15.32C37.52 15.57 37.23 15.76 36.9 15.89C36.57 16.02 36.22 16.07 35.87 16.05C35.18 16.07 34.52 15.8 34.01 15.32C33.53 14.8 33.28 14.11 33.3 13.4C33.28 12.69 33.55 12.02 34.05 11.55C34.55 11.07 35.22 10.82 35.91 10.82C36.72 10.82 37.35 11.07 37.81 11.55C38.27 12.05 38.52 12.72 38.5 13.4C38.52 14.11 38.27 14.8 37.77 15.32Z"
					fill="url(#paint0_linear_0_3)"
				/>
			</g>
			<defs>
				<linearGradient
					id="paint0_linear_0_3"
					x1="1.6"
					y1="44.43"
					x2="49.4"
					y2="4.32"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor={`${theme.iconGradientStop || "#0067C4"}`} />
					<stop
						offset="1"
						stopColor={`${theme.iconGradientStopOffset || "#96CEFF"}`}
					/>
				</linearGradient>
				<clipPath id="clip0_0_3">
					<rect
						width="50"
						height="48.75"
						fill="white"
						transform="translate(0.5)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default AI;
