import styled from "styled-components";

export const StyledSpringElement = styled.div`
	position: relative;

	&:hover {
		z-index: 20;
		border-radius: 20px;
	}
`;
