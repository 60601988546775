import React from "react";
import { useTheme } from "styled-components";

const Dribble = ({ className }) => {
	const theme = useTheme();
	return (
		<svg
			width="32"
			height="31"
			viewBox="0 0 32 31"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<path
				d="M16.0167 0C7.67539 0 0.90564 6.76975 0.90564 15.111C0.90564 23.4523 7.67539 30.2221 16.0167 30.2221C24.3429 30.2221 31.1277 23.4523 31.1277 15.111C31.1277 6.76975 24.3429 0 16.0167 0ZM25.9975 6.96619C27.8572 9.2318 28.8863 12.0652 28.914 14.9962C28.4893 14.9146 24.228 14.0457 19.9335 14.5867C19.8352 14.3736 19.7521 14.1439 19.6554 13.9142C19.3881 13.2841 19.1039 12.6613 18.8032 12.0465C23.5556 10.1123 25.7195 7.32583 25.9975 6.96619ZM16.0167 2.22888C19.2958 2.22888 22.2938 3.45892 24.5726 5.47473C24.3429 5.80113 22.392 8.40778 17.8028 10.1289C15.6888 6.24539 13.345 3.06452 12.9839 2.57341C13.978 2.34101 14.9958 2.22539 16.0167 2.22888ZM10.5268 3.4423C12.2562 5.83938 13.8538 8.32883 15.3125 10.8996C9.28018 12.5059 3.95354 12.4711 3.38083 12.4711C3.79612 10.5175 4.65712 8.68615 5.89667 7.12001C7.13621 5.55386 8.7208 4.29524 10.5268 3.4423ZM3.10127 15.1262V14.7333C3.66038 14.7484 9.91938 14.8315 16.3612 12.8973C16.739 13.6181 17.082 14.3555 17.4099 15.0929C17.2452 15.1428 17.0654 15.1911 16.9022 15.241C10.2473 17.3868 6.70677 23.2544 6.4121 23.747C4.28108 21.3814 3.10058 18.3101 3.10127 15.1262ZM16.0167 28.0265C13.1477 28.0314 10.3601 27.0736 8.10001 25.3065C8.32969 24.8305 10.953 19.7819 18.2289 17.2417C18.2622 17.2266 18.2788 17.2266 18.3105 17.2085C19.538 20.3748 20.4604 23.6512 21.0653 26.9929C19.4708 27.679 17.7525 28.0307 16.0167 28.0265ZM23.211 25.8127C23.0811 25.0269 22.392 21.2567 20.7026 16.6191C24.7524 15.9799 28.2929 17.0286 28.7341 17.1767C28.4585 18.9192 27.8256 20.5858 26.8752 22.0719C25.9247 23.5581 24.6772 24.8317 23.211 25.8127Z"
				fill={theme.footerIcon || "#fff"}
			/>
		</svg>
	);
};

export default Dribble;
