import styled from "styled-components";

export const StyledImage = styled.section`
	transition: all 0.3s ease-in-out;

	img {
		&:hover {
			border-radius: 5px;
		}
	}
`;
