export default function (state = { style: "dark" }, action) {
	switch (action.type) {
		case "SET_DARK_THEME":
			return { ...state, style: action.payload };
		case "SET_LIGHT_THEME":
			return { ...state, style: action.payload };
		default:
			return state;
	}
}
