import styled from "styled-components";

export const StyledDivider = styled.div`
	height: 0.25rem;
	background: ${(props) => props.theme.dividerColor};

	border-radius: 0.5rem;
	opacity: 0.5;
	scale: 0.5;

	@media screen and (max-width: 768px) {
		scale: 0.75;
	}
`;
