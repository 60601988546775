import styled from "styled-components";

export const StyledGSAPElement = styled.div`
	.gsap-element {
		opacity: 0;
		position: relative;
		transition: all 0.3s ease-in-out;

		&:hover {
			z-index: 20;
			border-radius: 20px;
		}
	}
`;
