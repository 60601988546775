import styled from "styled-components";

export const StyledToastContainer = styled.div`
  position: fixed;
  bottom: ${({ open }) => (open ? "0rem" : "-10rem")};
  width: 100%;
  margin: auto;
  z-index: 9999;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;

  .toast-container {
    background: radial-gradient(
      at 75% 75%,
      rgb(0, 140, 255, 1) 0,
      rgba(0, 19, 34, 1) 100%
    );
    animation: gradient 3s ease-in-out infinite;
    background-size: 600% 600%;
    border-radius: 0.5rem;
    padding: 0.75rem 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    column-gap: 1rem;
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
  }

  .toast-message {
    font-size: 1rem;
    font-weight: 500;
    color: #fff;
  }

  .check-mark {
    font-size: 1.5rem;
    color: #fff;
  }
`;
