import styled from "styled-components";

export const StyledButtonContainer = styled.div`
	width: 100%;

	.disabled {
		opacity: 0.7;
		pointer-events: none !important;
		cursor: not-allowed !important;
	}
`;

export const StyledButton = styled.button`
	width: fit-content;
	background: ${(props) => props.theme.buttonBackground};
	animation: gradient 15s ease-in-out infinite;
	background-size: 600% 600%;
	transition: all 0.3s ease-in-out;
	border-radius: 20px;
	color: #fff;
	font-size: 1rem;
	font-weight: 500;
	text-decoration: none;
	padding: 0.5rem 1.5rem;
	box-shadow: 0 0 1.25rem 0.25rem rgba(0, 0, 0, 0.1);
	border: none;

	&:hover {
		transform: scale(1.1) !important;
		animation: shadow-blue 1s ease-in-out infinite,
			gradient 7s ease-in-out infinite;
	}
`;
