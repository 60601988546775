import React from "react";
import { useTheme } from "styled-components";

const Sketch = ({ className }) => {
	const theme = useTheme();
	return (
		<svg
			width="50"
			height="49"
			viewBox="0 0 50 49"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<g clipPath="url(#clip0_0_53)">
				<path
					d="M41.14 0H8.86C3.97 0 0 3.96 0 8.86V39.89C0 44.78 3.97 48.75 8.86 48.75H41.14C46.03 48.75 50 44.78 50 39.89V8.86C50 3.96 46.03 0 41.14 0ZM21.35 42.81C17.45 44.1 12.12 41.04 8.93 39.08L11.04 35.54C13.14 36.87 16.37 38.89 18.95 38.89C19.93 38.89 20.04 38.83 19.6 37.91C19.18 36.95 18.33 36.14 17.62 35.45C14.31 32.16 9.87 30.33 7.02 26.52C5.81 24.89 4.74 22.81 5.41 20.75C6.16 18.35 8.49 17.89 10.7 18.23C14.58 18.79 18.74 20.68 21.99 22.83L19.89 26.37C18.14 25.29 16.27 24.37 14.37 23.58C13.29 23.12 11.16 21.91 9.93 22.16C8.72 22.41 9.91 23.68 10.49 24.37C11.88 26.05 13.53 27.5 15.37 28.66C17.95 30.39 20.52 32.18 22.37 34.79C24.24 37.33 25.06 41.35 21.35 42.81ZM28.29 26.45H23.95V22.12L36.77 9.31L41.1 13.64L28.29 26.45ZM44.45 10.31L42.33 12.41L37.99 8.08L40.12 5.95C40.34 5.74 40.63 5.63 40.93 5.63C41.23 5.63 41.53 5.74 41.74 5.95L44.45 8.66C44.91 9.1 44.91 9.85 44.45 10.31Z"
					fill="url(#paint0_linear_0_53)"
				/>
			</g>
			<defs>
				<linearGradient
					id="paint0_linear_0_53"
					x1="1.1"
					y1="44.43"
					x2="48.9"
					y2="4.32"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor={`${theme.iconGradientStop || "#0067C4"}`} />
					<stop
						offset="1"
						stopColor={`${theme.iconGradientStopOffset || "#96CEFF"}`}
					/>
				</linearGradient>
				<clipPath id="clip0_0_53">
					<rect width="50" height="48.75" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default Sketch;
