import styled from "styled-components";

export const StyledDistractor = styled.section`
	.loading {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
	}

	.margin-1 {
		margin: 1rem auto;
	}

	.margin-2 {
		margin: 2rem auto;
	}

	.margin-3 {
		margin: 3rem auto;
	}

	.margin-4 {
		margin: 4rem auto;
	}

	.margin-5 {
		margin: 5rem auto;
	}
`;
