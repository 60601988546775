import styled from "styled-components";

export const StyledBackToTop = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;

	flex-direction: column;
	position: fixed;
	bottom: 10px;
	right: 10px;

	.back-to-top-button {
		width: 35px;
		height: 35px;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0;
		border: none;
		position: relative;

		transition: all 0.3s ease-in-out;

		&:hover {
			cursor: pointer;
			transform: scale(1.1);
			&:after {
				animation: shadow-blue 1s ease-in-out infinite,
					gradient 7s ease-in-out infinite;
			}
		}

		&:after {
			content: "";
			position: absolute;
			width: inherit;
			height: inherit;
			border-radius: inherit;
			background: ${(props) => props.theme.backToTopBackground};
			z-index: -1;
			animation: gradient 10s ease-in-out infinite;
			background-size: 600% 600%;
		}
	}

	.back-to-top {
		width: 20px;
		height: 20px;
		color: ${(props) => props.theme.backToTopIcon};
	}
`;
