import React, { useEffect, useRef, useState } from "react";
import { StyledFooter, StyledFooterContainer } from "./styledComponents";
import resume from "./../../assets/documents/Resume-Jabez-du-Preez.pdf";
import Button from "../button";
import emailjs from "@emailjs/browser";
import SyncRoundedIcon from "@mui/icons-material/SyncRounded";
import { useInView } from "react-intersection-observer";
import { gsap } from "gsap";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch } from "react-redux";
import { setDialog, closeDialog } from "../../actions/dialogActions";
import Divider from "../divider";
import { useSpring, animated } from "@react-spring/web";
import {
	CV,
	Dribbble,
	Email,
	Insta,
	LinkedIn,
	WolfLogo,
} from "../../assets/images";

const siteKey = process.env.REACT_APP_SITE_KEY;
const serviceID = process.env.REACT_APP_SERVICE_ID;
const clientTemplateID = process.env.REACT_APP_CLIENT_TEMPLATE_ID;
const recipientTemplateID = process.env.REACT_APP_RECIPIENT_TEMPLATE_ID;
const publicKey = process.env.REACT_APP_PUBLIC_KEY;

export default function Footer() {
	const currentYear = new Date().getFullYear();
	const form = useRef();
	const [emailSent, setEmailSent] = useState("");
	const [recaptchad, setRecaptchad] = useState(false);
	const [error, setError] = useState(false);
	const dispatch = useDispatch();

	const sendButton = document.getElementById("contact-form-button");

	const tl = gsap.timeline().repeat(0);
	const [ref, inView] = useInView({
		threshold: 0.2,
		triggerOnce: true,
	});

	const windowWidth = window.innerWidth;

	const triggerAnimations = () => {
		if (inView && windowWidth > 450) {
			tl.fromTo(
				".footer-logo-container",
				{
					opacity: 0,
					y: -100,
				},
				{
					opacity: 1,
					y: 0,
					duration: 0.3,
					ease: "power1.out",
				}
			);

			tl.fromTo(
				".footer-tag-line",
				{
					opacity: 0,
					x: -100,
				},
				{
					opacity: 1,
					x: 0,
					duration: 0.3,
					ease: "power1.out",
				}
			);

			tl.fromTo(
				".footer-social",
				{
					opacity: 0,
					x: -100,
				},
				{
					opacity: 1,
					x: 0,
					duration: 0.3,
					ease: "power1.out",
					stagger: 0.1,
				}
			);
		} else if (inView && windowWidth < 450) {
			tl.fromTo(
				".footer-logo-container",
				{
					opacity: 0,
					y: -100,
				},
				{
					opacity: 1,
					y: 0,
					duration: 0.3,
					ease: "power1.out",
				}
			);

			tl.fromTo(
				".footer-tag-line",
				{
					opacity: 0,
					x: -100,
				},
				{
					opacity: 1,
					x: 0,
					duration: 0.3,
					ease: "power1.out",
				}
			);

			tl.fromTo(
				".footer-social",
				{
					opacity: 0,
					x: -100,
				},
				{
					opacity: 1,
					x: 0,
					duration: 0.3,
					ease: "power1.out",
					stagger: 0.1,
				}
			);
		}
	};

	useEffect(() => {
		triggerAnimations();
	}, [inView]);

	const updateButtonText = () => {
		switch (emailSent) {
			case "NotSent":
				return "Try Again";
			case "Sending":
				return (
					<SyncRoundedIcon fontSize="medium" className="animate-reverse-spin" />
				);
			default:
				return "Send";
		}
	};

	const contactFormSpring = useSpring({
		opacity: inView ? 1 : 0,
		transform: inView ? "translateY(0)" : "translateY(-100px)",
		from: { opacity: 0, transform: "translateY(-100px)" },
		config: {
			duration: 500,
		},
	});

	const detectTyping = (e) => {
		if (e.target.value) {
			setError(false);
		}
	};

	const sendEmail = (e) => {
		e.preventDefault();
		e.stopPropagation();
		tl.kill();
		if (
			!form.current.user_name.value ||
			!form.current.user_email.value ||
			!form.current.message.value
		) {
			setError(true);
			return;
		} else {
			setEmailSent("Sending");
			emailjs
				.sendForm(serviceID, clientTemplateID, form.current, publicKey)
				.then(
					(result) => {
						setEmailSent("Sent");
						dispatch(setDialog(true, "Email sent successfully", "success"));
						emailjs.sendForm(
							serviceID,
							recipientTemplateID,
							form.current,
							publicKey
						);
						form.current.reset();
						setTimeout(() => {
							dispatch(
								closeDialog(false, "Email sent successfully", "success")
							);
						}, 3000);
					},
					(error) => {
						setEmailSent("NotSent");
						setTimeout(() => {
							dispatch(
								setDialog(true, "Email not sent. Please try again", "failed")
							);
						}, 3000);

						console.log(error.text);
					}
				);
		}
	};

	const onChange = (value) => {
		if (value) {
			setRecaptchad(true);

			sendButton.classList.remove("disabled");
			sendButton.style.opacity = "1";
		}
	};

	return (
		<>
			<StyledFooterContainer id="main-footer">
				<Divider className={"margin-1"} />
				<StyledFooter id="footer" ref={ref} className="mt-16 mb-16">
					<div className="footer-logo-container">
						<WolfLogo className="footer-logo" />
						<div className="footer-tag-line-container">
							<h4 className="footer-tag-line">Learn. Grow. Evolve.</h4>
						</div>
						<div className="footer-socials-container">
							<a href="mailto:jabez.dupreez11@gmail.com" rel="noreferrer">
								<Email className="footer-social" />
							</a>
							<a
								href="https://www.instagram.com/wolf_digital_studio/"
								target="_blank"
								rel="noopener noreferrer"
							>
								<Insta className="footer-social" />
							</a>
							<a
								href="https://www.linkedin.com/in/jabez-du-preez/"
								target="_blank"
								rel="noopener noreferrer"
							>
								<LinkedIn className="footer-social" />
							</a>
							<a
								href="https://dribbble.com/jabez_dupreez11"
								target="_blank"
								rel="noopener noreferrer"
							>
								<Dribbble className="footer-social" />
							</a>
							<a
								href={resume}
								download="Resume-Jabez-du-Preez"
								target="_blank"
								rel="noopener noreferrer"
							>
								<CV className="footer-social" />
							</a>
						</div>
					</div>
					<animated.div
						style={contactFormSpring}
						className="footer-contact-area"
						id="footer-contact"
						ref={ref}
					>
						<h2 className="footer-contact-header">Let's chat!</h2>
						<div className="contact-form">
							<form
								ref={form}
								onChange={detectTyping}
								className="contact-form"
								onSubmit={sendEmail}
							>
								<div className="row-1">
									<div className="name-area">
										<label className="input-label" htmlFor="name">
											Name
										</label>
										<input
											type="text"
											name="user_name"
											placeholder="Name"
											className="contact-form-input"
										/>
									</div>
									<div className="name-area">
										<label className="input-label" htmlFor="email">
											Email
										</label>
										<input
											type="email"
											name="user_email"
											placeholder="Email"
											className="contact-form-input"
										/>
									</div>
								</div>
								<label className="input-label" htmlFor="message">
									Message
								</label>
								<textarea
									name="message"
									placeholder="Message"
									className="contact-form-input"
								></textarea>
								<div className="recaptcha-container">
									<ReCAPTCHA
										size="normal"
										sitekey={siteKey}
										onChange={onChange}
									/>
								</div>

								<Button
									id="contact-form-button"
									type="submit"
									disabled={!recaptchad}
									className="contact-form-button mt-4"
								>
									{updateButtonText()}
								</Button>
								{error && (
									<p className="text-left mt-4 text-yellow-500 font-bold">
										Please fill in all fields
									</p>
								)}
							</form>
						</div>
					</animated.div>
				</StyledFooter>
				<Divider className={"margin-1"} />
				<div className="footer-credits">
					<p className="mb-2 credits">
						Designed and developed by &copy; Wolf Digital Studio | {currentYear}
					</p>
					<p className="text-yellow-500 font-bold development">
						! Under Development !
					</p>
				</div>
			</StyledFooterContainer>
		</>
	);
}
