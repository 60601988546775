import React from "react";
import { StyledToastContainer } from "./styledComponents";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";

export default function Toast({ message, open, type }) {
	return (
		<StyledToastContainer open={open}>
			<div className="toast-container">
				<p className="toast-message">{message}</p>
				<div className="check-mark">
					{type === "success" ? (
						<CheckCircleRoundedIcon
							fontSize="large"
							style={{
								color: "rgb(0 255 86)",
							}}
						/>
					) : (
						<CancelRoundedIcon
							fontSize="large"
							style={{
								color: "rgb(255 0 0)",
							}}
						/>
					)}
				</div>
			</div>
		</StyledToastContainer>
	);
}
