import React from "react";
import { useTheme } from "styled-components";

const TailWind = ({ className }) => {
	const theme = useTheme();
	return (
		<svg
			width="50"
			height="49"
			viewBox="0 0 50 49"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<g clipPath="url(#clip0_0_56)">
				<path
					d="M41.14 0H8.86C3.96 0 0 3.97 0 8.86V39.89C0 44.79 3.96 48.75 8.86 48.75H41.14C46.03 48.75 50 44.79 50 39.89V8.86C50 3.97 46.03 0 41.14 0ZM25 35.8C20.24 35.8 18.13 33.61 16.26 31.71C15.1 30.54 14.07 29.5 12.63 29.14C10.09 28.51 7.87 29.46 5.96 31.99C7.24 26.91 10.4 24.38 15.48 24.38C20.24 24.38 22.35 26.57 24.22 28.47C25.38 29.63 26.41 30.68 27.85 31.04C30.38 31.67 32.61 30.72 34.51 28.18C33.24 33.27 30.08 35.8 25 35.8ZM34.51 24.38C29.76 24.38 27.64 22.19 25.78 20.29C24.64 19.12 23.59 18.08 22.14 17.72C19.61 17.09 17.38 18.04 15.48 20.57C16.76 15.49 19.92 12.96 25 12.96C29.76 12.96 31.87 15.15 33.73 17.05C34.89 18.21 35.92 19.26 37.37 19.62C39.9 20.25 42.13 19.3 44.03 16.76C42.76 21.85 39.6 24.38 34.51 24.38Z"
					fill="url(#paint0_linear_0_56)"
				/>
			</g>
			<defs>
				<linearGradient
					id="paint0_linear_0_56"
					x1="1.1"
					y1="44.43"
					x2="48.9"
					y2="4.32"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor={`${theme.iconGradientStop || "#0067C4"}`} />
					<stop
						offset="1"
						stopColor={`${theme.iconGradientStopOffset || "#96CEFF"}`}
					/>
				</linearGradient>
				<clipPath id="clip0_0_56">
					<rect width="50" height="48.75" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default TailWind;
