import React from "react";
import {
	AI,
	ReactJS,
	NEXT,
	JS,
	HTML,
	CSS,
	TailWind,
	Styled,
	PhotoShop,
	XD,
	Figma,
	Sketch,
	Wacom,
} from "./../../assets/images";

export const devToolboxConfig = [
	{
		name: "ReactJs",
		image: <ReactJS className={"mr-5 w-10 h-10 skill-image"} />,
		alt: "React Logo",
	},
	{
		name: "Nextjs",
		image: <NEXT className={"mr-5 w-10 h-10 skill-image"} />,
		alt: "Nextjs Logo",
	},
	{
		name: "JavaScript",
		image: <JS className={"mr-5 w-10 h-10 skill-image"} />,
		alt: "JavaScript Logo",
	},
	{
		name: "HTML",
		image: <HTML className={"mr-5 w-10 h-10 skill-image"} />,
		alt: "HTML Logo",
	},
	{
		name: "CSS",
		image: <CSS className={"mr-5 w-10 h-10 skill-image"} />,
		alt: "CSS Logo",
	},

	{
		name: "Tailwind CSS",
		image: <TailWind className={"mr-5 w-10 h-10 skill-image"} />,
		alt: "Tailwind CSS Logo",
	},
	{
		name: "Styled Components",
		image: <Styled className={"mr-5 w-10 h-10 skill-image"} />,
		alt: "Styled Components Logo",
	},
];

export const designToolboxConfig = [
	{
		name: "Adobe Illustrator",
		image: <AI className={"ml-5 w-10 h-10 skill-imageShappa"} />,
		alt: "Adobe Illustrator Logo",
	},
	{
		name: "Adobe Photoshop",
		image: <PhotoShop className={"ml-5 w-10 h-10 skill-imageShappa"} />,
		alt: "Adobe Photoshop Logo",
	},
	{
		name: "Adobe XD",
		image: <XD className={"ml-5 w-10 h-10 skill-imageShappa"} />,
		alt: "Adobe XD Logo",
	},
	{
		name: "Figma",
		image: <Figma className={"ml-5 w-10 h-10 skill-imageShappa"} />,
		alt: "Figma Logo",
	},
	{
		name: "A Handy Sketchpad",
		image: <Sketch className={"ml-5 w-10 h-10 skill-imageShappa"} />,
		alt: "A pencil drawing",
	},
	{
		name: "Wacom Intuos S",
		image: <Wacom className={"ml-5 w-10 h-10 skill-imageShappa"} />,
		alt: "Wacom Logo",
	},
];
