import styled from "styled-components";

const StyledNavbar = styled.div`
	display: flex;
	justify-content: center;

	.blur-background {
		backdrop-filter: blur(6px);
		background-color: ${(props) => props.theme.navbarBlurredBackground};
		transition: all 0.3s ease-in-out;
	}

	.no-background {
		backdrop-filter: blur(0px) !important;
		background-color: transparent !important;
		transition: all 0.3s ease-in-out !important;
	}

	.open {
		margin-top: 7rem;
	}

	.closed {
		margin-top: -125%;
	}
`;

const StyledNav = styled.nav`
	color: ${(props) => props.theme.navLinkColor};
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: fixed !important;
	top: 0;
	width: 76%;
	margin: 1rem auto;
	z-index: 999;
	padding: 0.75rem 1rem;
	box-sizing: border-box;
	background-color: ${(props) => props.theme.navBackground};
	border-radius: 10rem;

	animation: slide-down 0.5s ease-in-out;

	@media screen and (min-width: 1500px) {
		width: 80%;
	}

	.blur-background {
		backdrop-filter: blur(10px);
	}

	.logo,
	.link,
	.contact {
		opacity: 1;
		scale: 1;
	}

	.logo {
		@media screen and (max-width: 768px) {
			opacity: 1 !important;
			scale: 1 !important;
		}
		padding-left: 0.5rem;

		img {
			margin-top: 0.25rem;
			height: 2.5rem;
			transition: all 0.3s ease-in-out;

			&:hover {
				cursor: pointer;
				transform: scale(1.1) !important;
			}
		}
	}

	.links {
		display: flex;
		justify-content: space-between;
	}

	@media screen and (max-width: 768px) {
		padding: 1rem 1rem;
		width: 95%;

		.hamburger {
			display: block !important;
			cursor: pointer;
			z-index: 2;
			margin-right: 0.25rem;
		}

		.links {
			display: none;
		}

		.contact {
			display: none;
		}
	}

	.hamburger {
		display: none;
		position: relative;

		.MuiSvgIcon-root {
			fill: white;
		}

		&:hover {
			background-color: transparent;
		}
	}

	.cta-button {
		width: fit-content;
		background: ${(props) => props.theme.contactButtonBackground};
		animation: gradient 15s ease-in-out infinite;
		background-size: 600% 600%;
		transition: all 0.3s ease-in-out;
		border-radius: 20px;
		color: ${(props) => props.theme.contactButtonTextColor};
		font-size: 1rem;
		font-weight: 500;
		text-decoration: none;
		padding: 0.5rem 1.5rem;
		box-shadow: ${(props) => props.theme.contactButtonBoxShadow};
		border: none;

		&:hover {
			transform: scale(1.1) !important;
			animation: shadow-blue 1s ease-in-out infinite,
				gradient 7s ease-in-out infinite;
		}

		@media screen and (max-width: 768px) {
			display: none !important;
		}
	}

	.links .link {
		color: ${(props) => props.theme.navLinkColor};
		font-size: 1.05rem;
		font-weight: 500;
		text-decoration: none;
		margin: auto 1rem;
		padding: 0.5rem 1rem;
		transition: all 0.3s ease-in-out;
		border-radius: 20px;

		&:hover {
			transform: scale(1.15) !important;
			animation: shadow-white 1s ease-in-out infinite;
		}
	}

	.nav-right {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 1rem;
	}

	.theme-switcher {
		background: ${(props) => props.theme.themeSwitcherBackground};
		border-radius: 20px;
		width: fit-content;
		padding: 0.35rem;
		animation: none;

		&:hover {
			transform: scale(1.1) !important;
		}
	}
`;

const StyledMobileNav = styled.nav`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-end;
	background: ${(props) => props.theme.mobileNavBackground};
	height: 45vh;
	margin-top: ${({ open }) => (open ? "7rem" : "-125%")};
	width: 60%;
	text-align: left;
	padding: 2rem 1.25rem 1.5rem;
	border-radius: 2.625rem;
	position: fixed;
	top: 0;
	right: 4%;
	overflow: hidden;
	z-index: 1000;

	transition: margin-top 0.5s ease-in-out;

	.logo {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;
		margin-bottom: 1.5rem;
		width: fit-content;
		height: auto;

		.menu-logo {
			width: 25%;
			height: auto;
		}
	}

	.links {
		display: flex;
		flex-direction: column;
		row-gap: 0.75rem;
		width: fit-content;
	}

	.link {
		color: ${(props) => props.theme.mobileNavLink};
		font-size: 1.05rem;
		font-weight: 500;
		text-decoration: none;
		padding: 0.5rem 1rem;
		transition: all 0.3s ease-in-out;
		border-radius: 20px;

		text-align: right;

		&:hover {
			transform: scale(1.15) !important;
			animation: shadow-white 1s ease-in-out infinite;
		}
	}

	.contact {
		background: ${(props) => props.theme.contactButtonBackground};
		animation: gradient 15s ease-in-out infinite;
		background-size: 600% 600%;
		transition: all 0.3s ease-in-out;
		border-radius: 20px;
		color: ${(props) => props.theme.contactButtonTextColor};
		font-size: 1.05rem;
		font-weight: 500;
		margin-top: auto;
		text-decoration: none;
		padding: 0.75rem 1.5rem;
		box-shadow: ${(props) => props.theme.contactButtonBoxShadow};

		&:hover {
			transform: scale(1.1) !important;
			animation: shadow-blue 1s ease-in-out infinite,
				gradient 7s ease-in-out infinite;
		}
	}

	.contact-button {
		width: fit-content;
		background: ${(props) => props.theme.contactButtonBackground};
		animation: gradient 15s ease-in-out infinite;
		background-size: 600% 600%;
		transition: all 0.3s ease-in-out;
		border-radius: 10rem;
		color: ${(props) => props.theme.contactButtonTextColor};
		font-size: 1.05rem;
		font-weight: 500;
		margin-top: auto;
		text-decoration: none;
		padding: 0.75rem 1.75rem;
		box-shadow: ${(props) => props.theme.contactButtonBoxShadow};
		border: none;
		margin-left: 0.25rem;

		&:hover {
			transform: scale(1.1) !important;
			animation: shadow-blue 1s ease-in-out infinite,
				gradient 7s ease-in-out infinite;
		}
	}
`;

export { StyledNav, StyledNavbar, StyledMobileNav };
