import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store/store";
import { DialogManager } from "./components";
import packageJson from "../package.json";

const root = ReactDOM.createRoot(document.getElementById("root"));

console.log(`%cVersion: ${packageJson.version}`, "color: #007ae6");

root.render(
	<BrowserRouter>
		<Provider store={store}>
			<DialogManager />
			<App />
		</Provider>
	</BrowserRouter>
);
