import React, { useEffect } from "react";
import { StyledPage } from "./styledComponents";
import Navbar from "../navbar";
import Footer from "../footer";
import { Helmet } from "react-helmet";
import BackToTop from "../backToTop";

export default function Page({ children, title }) {
	useEffect(() => {
		(function (w, d, s, l, i) {
			w[l] = w[l] || [];
			w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
			var f = d.getElementsByTagName(s)[0],
				j = d.createElement(s),
				dl = l != "dataLayer" ? "&l=" + l : "";
			j.async = true;
			j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
			f.parentNode.insertBefore(j, f);
		})(window, document, "script", "dataLayer", "GTM-K86THT84");
	}, []);

	const page = () => {
		return (
			<StyledPage>
				<Helmet
					title={
						title ? `${title} | Wolf Digital Studio` : "Wolf Digital Studio"
					}
				>
					<script
						async
						src="https://www.googletagmanager.com/gtag/js?id=G-P3FW6E00LN"
						onLoad={() => {
							window.dataLayer = window.dataLayer || [];
							function gtag() {
								dataLayer.push(arguments);
							}
							gtag("js", new Date());

							gtag("config", "G-P3FW6E00LN");
						}}
					></script>
				</Helmet>
				<Navbar />
				{children}
				<Footer />
				<BackToTop />
				<noscript>
					<iframe
						src="https://www.googletagmanager.com/ns.html?id=GTM-K86THT84"
						height="0"
						width="0"
						style="display: none; visibility: hidden"
					></iframe>
				</noscript>
			</StyledPage>
		);
	};

	const renderPage = () => {
		return page();
	};

	return <>{renderPage()}</>;
}
