import styled from "styled-components";

export const StyledExperienceBubble = styled.div`
	.card {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		color: ${(props) => props.theme.cardTextColor};

		.card-icon {
			display: flex;
			color: ${(props) => props.theme.cardIconColor};
			position: absolute;
			transition: all 0.5 ease-in-out;
			background: ${(props) => props.theme.cardIconBackground};
			width: 12rem;
			height: 12rem;
			border-radius: 8rem;
			text-align: center;
			font-size: 1.5rem;
			opacity: 1;

			animation: shadow-blue 1.5s ease-in-out infinite;

			&:hover + .experience-card {
				clip-path: circle(100%);
			}

			&:active + .experience-card {
				clip-path: circle(100%);
			}

			h1 {
				margin: auto;
				max-width: 85%;
			}
		}

		.experience-card {
			background-color: ${(props) => props.theme.experienceCardBackground};
			clip-path: circle(0% at 50% 50%);
			transition: all 0.5s ease-in-out;
			animation: shadow-blue 1s ease-in-out infinite;

			.card-date {
				color: ${(props) => props.theme.cardDateColor};
			}

			.card-description {
				color: ${(props) => props.theme.cardDescriptionColor};
			}

			.card-title {
				color: ${(props) => props.theme.cardTitleColor};
			}

			&:hover {
				clip-path: circle(100%);
			}
		}
	}
`;
