import React from "react";
import { useTheme } from "styled-components";

const Insta = ({ className }) => {
	const theme = useTheme();
	return (
		<svg
			width="31"
			height="31"
			viewBox="0 0 31 31"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<path
				d="M9.40796 0H22.1012C26.9368 0 30.8656 3.92887 30.8656 8.7644V21.4577C30.8656 23.7821 29.9423 26.0114 28.2986 27.6551C26.655 29.2987 24.4257 30.2221 22.1012 30.2221H9.40796C4.57243 30.2221 0.643555 26.2932 0.643555 21.4577V8.7644C0.643555 6.43994 1.56694 4.21068 3.21059 2.56703C4.85423 0.92339 7.08349 0 9.40796 0ZM9.10574 3.02221C7.66297 3.02221 6.27929 3.59535 5.2591 4.61554C4.2389 5.63573 3.66576 7.01941 3.66576 8.46218V21.7599C3.66576 24.767 6.09864 27.1999 9.10574 27.1999H22.4035C23.8462 27.1999 25.2299 26.6267 26.2501 25.6065C27.2703 24.5864 27.8434 23.2027 27.8434 21.7599V8.46218C27.8434 5.45509 25.4106 3.02221 22.4035 3.02221H9.10574ZM23.6879 5.28886C24.1889 5.28886 24.6693 5.48787 25.0235 5.84211C25.3778 6.19634 25.5768 6.67678 25.5768 7.17775C25.5768 7.67871 25.3778 8.15915 25.0235 8.51339C24.6693 8.86762 24.1889 9.06663 23.6879 9.06663C23.1869 9.06663 22.7065 8.86762 22.3523 8.51339C21.998 8.15915 21.799 7.67871 21.799 7.17775C21.799 6.67678 21.998 6.19634 22.3523 5.84211C22.7065 5.48787 23.1869 5.28886 23.6879 5.28886ZM15.7546 7.55552C17.7584 7.55552 19.6802 8.35155 21.0972 9.76848C22.5141 11.1854 23.3101 13.1072 23.3101 15.111C23.3101 17.1149 22.5141 19.0367 21.0972 20.4536C19.6802 21.8705 17.7584 22.6666 15.7546 22.6666C13.7507 22.6666 11.829 21.8705 10.412 20.4536C8.9951 19.0367 8.19908 17.1149 8.19908 15.111C8.19908 13.1072 8.9951 11.1854 10.412 9.76848C11.829 8.35155 13.7507 7.55552 15.7546 7.55552ZM15.7546 10.5777C14.5523 10.5777 13.3992 11.0553 12.5491 11.9055C11.6989 12.7557 11.2213 13.9087 11.2213 15.111C11.2213 16.3134 11.6989 17.4664 12.5491 18.3166C13.3992 19.1667 14.5523 19.6444 15.7546 19.6444C16.9569 19.6444 18.11 19.1667 18.9601 18.3166C19.8103 17.4664 20.2879 16.3134 20.2879 15.111C20.2879 13.9087 19.8103 12.7557 18.9601 11.9055C18.11 11.0553 16.9569 10.5777 15.7546 10.5777Z"
				fill={theme.footerIcon || "#fff"}
			/>
		</svg>
	);
};

export default Insta;
