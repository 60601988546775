import React from "react";
import { StyledExperienceBubble } from "./styled";
import { useSpring, animated } from "@react-spring/web";
import { useInView } from "react-intersection-observer";

export default function ExperienceBubble({ item }) {
	const [ref, inView] = useInView({
		threshold: 0.5,
		triggerOnce: true,
	});

	const experienceSpring = useSpring({
		opacity: inView ? 1 : 0,
		transform: inView ? "translateY(0)" : "translateY(50px)",
		from: { opacity: 0, transform: "translateY(50px)" },
	});

	return (
		<StyledExperienceBubble>
			<animated.div
				style={experienceSpring}
				key={item.id}
				className="card mb-8"
				ref={ref}
			>
				<div className="card-icon mb-2">
					<h1 className="font-medium">{item.company}</h1>
				</div>
				<div className="experience-card p-6 rounded-2xl text-center justify-center ">
					<div className="card-date font-semibold text-lg mb-2">
						{item.date}
					</div>
					<div className="card-company primary font-semibold text-2xl mb-2">
						{item.company}
					</div>
					<div className="card-title font-semibold text-xl mb-2">
						{item.title}
					</div>
					{item.description.map((desc) => {
						return (
							<div key={desc.id} className="card-description text-lg mb-2">
								{desc.description}
							</div>
						);
					})}
				</div>
			</animated.div>
		</StyledExperienceBubble>
	);
}
