import React from "react";
import { StyledHero, StyledHeroContainer } from "./styledComponents";
import heroImage from "./../../assets/images/hero-img.webp";
import { Arrow } from "../../assets/images";
import blob1 from "./../../assets/images/blob1.svg";
import blob2 from "./../../assets/images/blob2.svg";
import blob3 from "./../../assets/images/blob3.svg";
import blob4 from "./../../assets/images/blob4.svg";
import { useInView } from "react-intersection-observer";
import { gsap } from "gsap";
import { TypeAnimation } from "react-type-animation";
import { useSpring, animated } from "@react-spring/web";

export default function Hero() {
	const [ref, inView] = useInView({
		threshold: 0.5,
		triggerOnce: true,
	});
	let windowWidth = window.innerWidth;

	const scrollTo = (id) => {
		const element = document.getElementById(id);
		element.scrollIntoView({
			behavior: "smooth",
		});
	};

	const tl = gsap.timeline();

	if (inView && windowWidth > 450) {
		tl.fromTo(
			".hero-image-container",
			{
				opacity: 0,
				scale: 0,
			},
			{
				opacity: 1,
				scale: 1,
				duration: 0.5,
				ease: "power1.out",
			}
		);

		tl.fromTo(
			".emphasis",
			{
				opacity: 0,
				scale: 0,
			},
			{
				opacity: 1,
				scale: 1,
				duration: 0.5,
				ease: "power1.out",
				stagger: 0.3,
			}
		);
	} else if (inView && windowWidth < 450) {
		tl.fromTo(
			".hero-image-container",
			{
				opacity: 0,

				scale: 0,
			},
			{
				opacity: 1,
				scale: 1,
				duration: 0.5,
				ease: "power1.out",
			}
		);

		tl.fromTo(
			".emphasis",
			{
				opacity: 0,
				scale: 0,
				x: "random(-100, 100)",
			},
			{
				opacity: 1,
				scale: 1,
				y: 0,
				x: 0,
				rotateZ: 0,
				duration: 0.5,
				ease: "power1.out",
				stagger: 0.3,
			}
		);
	}

	const typeSequence = () => {
		return (
			<TypeAnimation
				sequence={[
					"Web Developer",
					2500,
					"Logo Designer",
					2500,
					"UI/UX Designer",
					2500,
				]}
				wrapper="span"
				speed={10}
				deletionSpeed={20}
				repeat={Infinity}
				className="emphasis"
				cursor={true}
			/>
		);
	};

	const subHeadingProps = useSpring({
		from: { opacity: 0, transform: "translateY(-100px)" },
		to: { opacity: 1, transform: "translateY(0px)" },
		config: { duration: 700, mass: 5, friction: 120, tension: 120 },
	});

	const headingProps = useSpring({
		from: { opacity: 0, transform: "translateY(100px)" },
		to: { opacity: 1, transform: "translateY(0px)" },
		config: { duration: 700, mass: 5, friction: 120, tension: 120 },
	});

	return (
		<StyledHeroContainer>
			<StyledHero ref={ref}>
				<div className="hero-image-container">
					<img className="hero-img" src={heroImage} alt="hero-img" />
				</div>
				<div className="heading-container">
					<animated.h3 style={headingProps} className="heading">
						Hi there!
					</animated.h3>
					<animated.h1 style={headingProps} className="heading">
						I'm <span className="emphasis">Jabez</span>
					</animated.h1>
					<animated.h1 style={headingProps} className="heading">
						a {typeSequence()}
					</animated.h1>
					<animated.h1 style={subHeadingProps} className="sub-heading">
						Feel free to look around!
					</animated.h1>
				</div>
				<div className="blobs">
					<img className="blob blob-1" alt="blob" src={blob1} />
					<img className="blob blob-2" alt="blob" src={blob2} />
					<img className="blob blob-3" alt="blob" src={blob3} />
					<img className="blob blob-4" alt="blob" src={blob4} />
				</div>
			</StyledHero>
			<div className="arrow-container">
				<Arrow
					className={" arrow animate-bounce w-10 h-10"}
					onClick={() => {
						scrollTo("about");
					}}
				/>
			</div>
		</StyledHeroContainer>
	);
}
