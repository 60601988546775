const initialState = {
	logos: "",
	loading: true,
};
export default function (state = initialState, action) {
	switch (action.type) {
		case "SET_LOGOS":
			return { ...state, logos: action.payload };
		case "LOADING_LOGOS":
			return { ...state, loading: action.payload };
		default:
			return state;
	}
}
