import styled from "styled-components";

export const StyledExperienceContainer = styled.div`
	scroll-margin-top: 8rem;
`;

export const StyledExperienceCardContainer = styled.section`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-evenly;

	@media screen and (max-width: 768px) {
		flex-direction: column;
	}
`;

export const StyledStoryContainer = styled.section`
	width: 76%;
	margin-left: auto;
	margin-right: auto;

	.story {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;
		margin: 0 0 3rem 0;
		color: ${(props) => props.theme.storyTextColor};
		font-size: 1.2rem;
	}
`;
