import React, { useEffect } from "react";
import { StyledDialogManager } from "./styledComponents";
import { useSelector } from "react-redux";
import Toast from "../toast";
import { useDispatch } from "react-redux";

export default function DialogManager() {
	const dialog = useSelector((state) => state.dialog);

	return (
		<StyledDialogManager>
			<Toast
				message={dialog.dialog.message}
				open={dialog.dialog.open}
				setOpen={dialog.dialog.open}
				type={dialog.dialog.type}
			/>
		</StyledDialogManager>
	);
}
