import React from "react";
import { useTheme } from "styled-components";

const Wolf = ({ className }) => {
	const theme = useTheme();
	return (
		<svg
			width="53"
			height="40"
			viewBox="0 0 53 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<g clipPath="url(#clip0_0_83)">
				<path
					d="M14.3154 4.53068L9.55124 4.53768C9.28602 4.53768 9.04368 4.38686 8.92788 4.14779L6.92657 0.026578L11.652 0.0205078C11.9415 0.0205078 12.2058 0.185338 12.3319 0.445423L14.3154 4.53021V4.53068Z"
					fill={theme.logoAccentColor || "#0086FF"}
				/>
				<path
					d="M31.6067 0.00699268L29.6054 4.12774C29.4896 4.36634 29.2477 4.5181 28.982 4.51763C27.6017 4.5153 25.387 4.51389 24.0161 4.5167C23.7504 4.51716 23.5085 4.36587 23.3927 4.1268L21.3914 0.0060588C23.9101 -0.00141224 29.0726 0.00045552 31.6067 0.00699268Z"
					fill={theme.logoAccentColor || "#0086FF"}
				/>
				<path
					d="M46.0725 0.0285108L44.0712 4.14972C43.9554 4.38833 43.713 4.53962 43.4478 4.53962L38.6832 4.53261C38.9311 4.02225 40.1461 1.52085 40.6672 0.446422C40.7933 0.186336 41.0571 0.0215067 41.3466 0.0219736L46.073 0.0289777L46.0725 0.0285108Z"
					fill={theme.logoAccentColor || "#0086FF"}
				/>
				<path
					d="M38.7476 0.0177473C37.2907 3.01737 31.6314 14.6713 30.0728 17.8805C29.5988 16.9051 28.0084 13.6304 27.5634 12.7138C28.9082 9.94395 32.0461 3.48197 33.5253 0.435191C33.6519 0.174639 33.9157 0.0102762 34.2052 0.0107432L38.7476 0.0177473Z"
					fill={theme.logoMainColor || "#0067C4"}
				/>
				<path
					d="M25.4361 27.4285C24.1609 30.0541 20.5561 37.4752 19.534 39.5787C19.4233 39.8066 19.0983 39.8066 18.9876 39.5787C16.6959 34.8594 2.54762 5.72468 0.0396844 0.559861C-0.0859224 0.301176 0.102721 0 0.390356 0H4.31452C4.60309 0 4.86644 0.164363 4.99251 0.424448C6.8981 4.34814 17.2399 25.6443 19.1254 29.5274C19.1805 29.6413 19.3425 29.6409 19.3976 29.5274L22.9263 22.2614C23.4002 23.2368 24.9906 26.5124 25.4356 27.4285H25.4361Z"
					fill={theme.logoMainColor || "#0067C4"}
				/>
				<path
					d="M52.6096 0C52.8973 0 53.0859 0.300709 52.9603 0.559394C50.4178 5.79519 36.3181 34.8281 34.0105 39.5801C33.8998 39.8075 33.5762 39.8075 33.466 39.5801C31.5422 35.6196 19.9168 11.6833 14.2505 0.0168098L18.7934 0.00980573C19.0824 0.00980573 19.3462 0.173702 19.4723 0.433787C23.99 9.73616 29.0894 20.2353 33.6015 29.5279C33.6566 29.6418 33.8186 29.6409 33.8737 29.5274C35.8031 25.5542 46.0771 4.39764 48.007 0.423981C48.1331 0.164363 48.3964 0 48.685 0H52.6096Z"
					fill={theme.logoMainColor || "#0067C4"}
				/>
			</g>
			<defs>
				<clipPath id="clip0_0_83">
					<rect width="53" height="39.7506" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default Wolf;
