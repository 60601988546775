import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "swiper/css";
import "react-toastify/dist/ReactToastify.css";

import React, { useEffect, lazy, useState } from "react";
import { ThemeProvider } from "styled-components";
import GlobalStyles from "./globalStyles";
import { light, dark } from "./configs/theme";
import { useDispatch, useSelector } from "react-redux";
import { setLightTheme, setDarkTheme } from "./actions";

const Router = lazy(() => import("./Router"));

function App() {
	const dispatch = useDispatch();
	const [theme, setTheme] = useState("light");
	const currentTheme = useSelector((state) => state.theme.style);
	const savedTheme = localStorage.getItem("theme");

	// if cookie theme is set, set the page theme to the cookie theme
	// else set the page theme to the theme style in the redux store
	const setPageTheme = () => {
		if (savedTheme) {
			setTheme(savedTheme);

			if (savedTheme === "light") dispatch(setLightTheme());
			else dispatch(setDarkTheme());
		} else {
			setTheme(currentTheme);
		}
	};

	useEffect(() => {
		setPageTheme();
	}, [currentTheme]);

	useEffect(() => {
		if ("scrollRestoration" in window.history) {
			window.history.scrollRestoration = "manual";
		}

		window.addEventListener("contextmenu", disableRightClick);

		return () => {
			window.removeEventListener("contextmenu", disableRightClick);
		};
	}, []);

	const disableRightClick = (e) => {
		e.preventDefault();
	};

	return (
		<>
			<ThemeProvider theme={theme === "light" ? light : dark}>
				<Router />
				<GlobalStyles />
			</ThemeProvider>
		</>
	);
}

export default App;
