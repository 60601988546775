import React from "react";
import { useTheme } from "styled-components";

const Styled = ({ className }) => {
	const theme = useTheme();
	return (
		<svg
			width="50"
			height="49"
			viewBox="0 0 50 49"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<g clipPath="url(#clip0_0_65)">
				<path
					d="M41.14 0H8.86C3.97 0 0 3.96 0 8.86V39.89C0 44.78 3.97 48.75 8.86 48.75H41.14C46.04 48.75 50 44.78 50 39.89V8.86C50 3.96 46.04 0 41.14 0ZM30.33 23.02C29.84 24.15 28.49 25.41 26.64 26.75C25.08 27.89 24.09 25.73 24.48 24.95C25.32 24.26 27.07 22.49 28.43 21.62C30.13 20.42 30.92 21.79 30.34 23.02H30.33ZM25.69 20.91C25.69 21.44 25.69 22.12 24.48 23.65C24.48 23.65 21.91 25.81 21.25 25.84C20.59 25.84 19.63 25.03 19.72 23.93C21.3 22.14 25.59 18.55 25.69 20.9V20.91ZM14.12 28.47L19.08 24.44C19.35 25.94 20.97 27.26 22.55 25.95L14.13 33.13V28.47H14.12ZM13.11 29.56L11.82 30.85L5.35 24.38L11.82 17.91L13.11 19.19L7.92 24.38L13.11 29.57V29.56ZM14.12 35.53V34.46C14.77 33.56 20.07 29.19 23.83 26C24.88 28.12 26.4 28.17 28.75 25.48C28.81 25.73 28.87 25.99 28.75 26.62C23.96 30.9 19.45 35.07 18.08 35.53H14.11H14.12ZM26.79 35.53H19.72L25.68 30.51C26.71 32.85 28.54 32.51 30.91 30.11C30.69 31.94 29.02 33.55 26.79 35.53ZM36.43 17.5L34.91 17.52L33.08 23.71C33.19 25.89 32.09 25.93 31.94 26.93C31.58 29.45 28.77 31.51 27.69 31.51C26.97 31.49 26.45 30.67 26.27 29.95C28.3 27.71 31.02 25.95 31.02 25.95C31.19 24.77 30.82 24.4 32.07 23.39L33.27 17.08C32.25 16.4 31.77 16.41 31.9 15.56L32.28 13.21H36.42V17.49L36.43 17.5ZM38.18 30.85L36.89 29.57L42.08 24.38L36.89 19.19L38.18 17.9L44.65 24.37L38.18 30.84V30.85Z"
					fill="url(#paint0_linear_0_65)"
				/>
			</g>
			<defs>
				<linearGradient
					id="paint0_linear_0_65"
					x1="1.1"
					y1="44.43"
					x2="48.9"
					y2="4.32"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor={`${theme.iconGradientStop || "#0067C4"}`} />
					<stop
						offset="1"
						stopColor={`${theme.iconGradientStopOffset || "#96CEFF"}`}
					/>
				</linearGradient>
				<clipPath id="clip0_0_65">
					<rect width="50" height="48.75" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default Styled;
