import React from "react";
import { useTheme } from "styled-components";

const Arrow = ({ className, onClick }) => {
	const theme = useTheme();
	return (
		<svg
			width="22"
			height="13"
			viewBox="0 0 22 13"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
			onClick={onClick}
		>
			<path
				d="M21 1L11.7328 10.9801C11.3372 11.4061 10.6628 11.4061 10.2672 10.9801L1 1"
				stroke={theme.arrow || "#000"}
				strokeWidth="2"
				strokeLinecap="round"
			/>
		</svg>
	);
};

export default Arrow;
