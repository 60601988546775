/**
 * @method setDialog
 * @param {*} open
 * @param {*} message
 * @param {*} type
 * @returns dialog props
 */
export const setDialog = (open, message, type) => {
	return {
		type: "SET_DIALOG",
		payload: { open, message, type },
	};
};

export const closeDialog = (open, message, type) => {
	return {
		type: "SET_DIALOG",
		payload: { open, message, type },
	};
};
