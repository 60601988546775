import React from "react";
import { useTheme } from "styled-components";

const HTML = ({ className }) => {
	const theme = useTheme();
	return (
		<svg
			width="51"
			height="49"
			viewBox="0 0 51 49"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<g clipPath="url(#clip0_0_20)">
				<path
					d="M18.11 28.57L17.22 18.58H33.76L33.47 21.88H20.83L21.13 25.27H33.17L33.09 26.15L32.26 35.41L25.48 37.29L18.72 35.41L18.26 30.22H21.57L21.81 32.86L25.49 33.85L29.17 32.85L29.56 28.57H18.11Z"
					fill="url(#paint0_linear_0_20)"
				/>
				<path
					d="M41.64 0H9.36C4.47 0 0.5 3.97 0.5 8.86V39.89C0.5 44.79 4.47 48.75 9.36 48.75H41.64C46.53 48.75 50.5 44.79 50.5 39.89V8.86C50.5 3.97 46.53 0 41.64 0ZM32.24 5.76H33.92V9.12H36.28V10.78H32.24V5.76ZM25.76 5.76H27.51L28.58 7.52L29.66 5.76H31.41V10.78H29.74V8.29L28.58 10.08H28.55L27.4 8.29V10.78H25.76V5.76ZM20.39 5.76H25.02V7.42H23.54V10.78H21.86V7.42H20.39V5.76ZM14.76 5.76H16.44V7.42H17.98V5.76H19.66V10.78H17.98V9.1H16.44V10.78H14.76V5.76ZM36.29 40L25.48 43L14.71 40.01L11.53 13.08H39.46L36.29 40Z"
					fill="url(#paint1_linear_0_20)"
				/>
			</g>
			<defs>
				<linearGradient
					id="paint0_linear_0_20"
					x1="17.06"
					y1="33.44"
					x2="34.18"
					y2="19.08"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor={`${theme.iconGradientStop || "#0067C4"}`} />
					<stop
						offset="1"
						stopColor={`${theme.iconGradientStopOffset || "#96CEFF"}`}
					/>
				</linearGradient>
				<linearGradient
					id="paint1_linear_0_20"
					x1="1.6"
					y1="44.43"
					x2="49.4"
					y2="4.32"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor={`${theme.iconGradientStop || "#0067C4"}`} />
					<stop
						offset="1"
						stopColor={`${theme.iconGradientStopOffset || "#96CEFF"}`}
					/>
				</linearGradient>
				<clipPath id="clip0_0_20">
					<rect
						width="50"
						height="48.75"
						fill="white"
						transform="translate(0.5)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default HTML;
