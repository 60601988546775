import React from "react";
import { StyledSpringElement } from "./styled";
import { useInView, animated } from "@react-spring/web";
import CustomImage from "../image";

const SpringElement = ({ className, image, animationCompleted }) => {
	const [ref, springs] = useInView(
		() => ({
			from: {
				opacity: 0,
				y: 100,
			},
			to: {
				opacity: 1,
				y: 0,
			},
		}),
		{
			rootMargin: "-5% 0%",
		}
	);

	return (
		<animated.div ref={ref} style={springs} className={className}>
			<StyledSpringElement>
				<div className="logo-item">
					<CustomImage animationCompleted={true} image={image} />
				</div>
			</StyledSpringElement>
		</animated.div>
	);
};

export default SpringElement;
