import React, { useState, useEffect } from "react";
import { StyledImage } from "./styled";
import { CircularProgress } from "@mui/material";
import SentimentVeryDissatisfiedRoundedIcon from "@mui/icons-material/SentimentVeryDissatisfiedRounded";

export default function CustomImage({ image, animationCompleted }) {
	const [loading, setLoading] = useState(true);
	const [progressIndicator, setProgressIndicator] = useState();
	const imageUrl = image?.src;

	useEffect(() => {
		if (animationCompleted) {
			fetchImage();
		}
	}, [animationCompleted]);

	/**
	 * @method fetchImage
	 */
	const fetchImage = async () => {
		try {
			const response = await fetch(imageUrl);
			if (!response.ok) {
				throw new Error("Network response was not ok");
			}

			const contentLength = Number(response.headers.get("Content-Length"));
			if (isNaN(contentLength)) {
				throw new Error("Invalid Content-Length header");
			}

			const reader = response.body.getReader();

			let readerDone = false;
			let receivedLength = 0;

			while (!readerDone) {
				const { done, value } = await reader.read();
				readerDone = done;
				setLoading(!done); // Update loading status
				receivedLength += value?.length;
				let progress = (receivedLength / contentLength) * 100;
				setProgressIndicator(() => {
					return (
						<div className="progress-indicator flex justify-center">
							<CircularProgress
								variant="determinate"
								value={progress}
								className={"m-12"}
							/>
						</div>
					);
				});
			}
		} catch (error) {
			console.error("Error fetching image:", error);
			setProgressIndicator(() => {
				return (
					<div className="progress-indicator flex justify-center">
						<SentimentVeryDissatisfiedRoundedIcon
							className={"m-12"}
							color="white"
						/>
					</div>
				);
			});
		}
	};

	return (
		<StyledImage>
			{loading && progressIndicator}
			<img
				src={image.src}
				alt={image.title}
				style={{
					display: loading ? "none" : "block",
				}}
			/>
		</StyledImage>
	);
}
