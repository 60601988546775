import React from "react";
import { useTheme } from "styled-components";

const NEXT = ({ className }) => {
	const theme = useTheme();
	return (
		<svg
			width="50"
			height="49"
			viewBox="0 0 50 49"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<g clipPath="url(#clip0_0_45)">
				<path
					d="M29.1801 18.08V26.9L31.2901 30.12V18.08C31.2901 17.26 29.1801 17.26 29.1801 18.08Z"
					fill="url(#paint0_linear_0_45)"
				/>
				<path
					d="M41.15 0H8.85C3.96 0 0 3.96 0 8.85V39.89C0 44.79 3.96 48.74 8.85 48.74H41.15C46.05 48.74 50 44.78 50 39.89V8.85C50 3.95 46.04 0 41.15 0ZM33.23 37.03L20.82 18.31C20.36 17.55 18.74 17.62 18.74 18.5V31.79C18.74 32.89 20.84 32.89 20.84 31.79V21.88L31.73 37.9C21.76 42.88 9.79 35.57 9.89 24.26C10 12.67 22.7 5.5 32.69 11.37C42.42 17.09 42.54 31.04 33.22 37.04L33.23 37.03Z"
					fill="url(#paint1_linear_0_45)"
				/>
			</g>
			<defs>
				<linearGradient
					id="paint0_linear_0_45"
					x1="27.8401"
					y1="26.01"
					x2="33.8501"
					y2="20.97"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor={`${theme.iconGradientStop || "#0067C4"}`} />
					<stop
						offset="1"
						stopColor={`${theme.iconGradientStopOffset || "#96CEFF"}`}
					/>
				</linearGradient>
				<linearGradient
					id="paint1_linear_0_45"
					x1="1.1"
					y1="44.43"
					x2="48.9"
					y2="4.31"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor={`${theme.iconGradientStop || "#0067C4"}`} />
					<stop
						offset="1"
						stopColor={`${theme.iconGradientStopOffset || "#96CEFF"}`}
					/>
				</linearGradient>
				<clipPath id="clip0_0_45">
					<rect width="50" height="48.74" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default NEXT;
