import React from "react";
import { useTheme } from "styled-components";

const Figma = ({ className }) => {
	const theme = useTheme();
	return (
		<svg
			width="50"
			height="49"
			viewBox="0 0 50 49"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<g clipPath="url(#clip0_0_15)">
				<path
					d="M25 18.07V24.38C25 20.9 27.82 18.07 31.31 18.07H25ZM41.14 0H8.86C3.97 0 0 3.97 0 8.87V39.89C0 44.79 3.97 48.75 8.86 48.75H41.14C46.03 48.75 50 44.79 50 39.89V8.87C50 3.97 46.03 0 41.14 0ZM37.61 24.38C37.61 27.86 34.79 30.69 31.31 30.69C27.83 30.69 25 27.86 25 24.38V37C25 38.67 24.33 40.27 23.15 41.46C21.97 42.64 20.36 43.3 18.69 43.3C17.02 43.3 15.41 42.64 14.23 41.46C13.05 40.27 12.38 38.67 12.38 37C12.38 35.33 13.05 33.72 14.23 32.54C15.41 31.35 17.02 30.69 18.69 30.69C17.02 30.69 15.41 30.02 14.23 28.84C13.05 27.66 12.38 26.05 12.38 24.38C12.38 22.71 13.05 21.1 14.23 19.92C15.41 18.74 17.02 18.07 18.69 18.07C17.02 18.07 15.41 17.41 14.23 16.22C13.05 15.04 12.38 13.44 12.38 11.76C12.38 10.08 13.05 8.49 14.23 7.3C15.41 6.12 17.02 5.46 18.69 5.46H31.31C32.98 5.46 34.58 6.12 35.77 7.3C36.95 8.49 37.61 10.09 37.61 11.76C37.61 13.43 36.95 15.04 35.77 16.22C34.59 17.4 32.98 18.07 31.31 18.07C34.79 18.07 37.61 20.9 37.61 24.38ZM25 24.38C25 20.9 27.82 18.07 31.31 18.07H25V24.38Z"
					fill="url(#paint0_linear_0_15)"
				/>
			</g>
			<defs>
				<linearGradient
					id="paint0_linear_0_15"
					x1="1.1"
					y1="44.43"
					x2="48.9"
					y2="4.32"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor={`${theme.iconGradientStop || "#0067C4"}`} />
					<stop
						offset="1"
						stopColor={`${theme.iconGradientStopOffset || "#96CEFF"}`}
					/>
				</linearGradient>
				<clipPath id="clip0_0_15">
					<rect width="50" height="48.75" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default Figma;
