import React from "react";
import { useTheme } from "styled-components";

const WolfLogo = ({ className }) => {
	const theme = useTheme();
	return (
		<svg
			width="1136"
			height="852"
			viewBox="0 0 1136 852"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<g clipPath="url(#clip0_0_92)">
				<path
					d="M306.58 97.0299L204.55 97.1799C198.87 97.1799 193.68 93.9499 191.2 88.8299L148.34 0.569941L249.54 0.439941C255.74 0.439941 261.4 3.96994 264.1 9.53994L306.58 97.0199V97.0299Z"
					fill={theme.footerLogo || "#D8E6E6"}
				/>
				<path
					d="M676.89 0.14998L634.03 88.4C631.55 93.51 626.37 96.76 620.68 96.75C591.12 96.7 543.69 96.67 514.33 96.73C508.64 96.74 503.46 93.5 500.98 88.38L458.12 0.12998C512.06 -0.03002 622.62 0.00997999 676.89 0.14998Z"
					fill={theme.footerLogo || "#D8E6E6"}
				/>
				<path
					d="M986.69 0.609992L943.83 88.87C941.35 93.98 936.16 97.22 930.48 97.22L828.44 97.07C833.75 86.14 859.77 32.57 870.93 9.55999C873.63 3.98999 879.28 0.459992 885.48 0.469992L986.7 0.619992L986.69 0.609992Z"
					fill={theme.footerLogo || "#D8E6E6"}
				/>
				<path
					d="M829.82 0.380002C798.62 64.62 677.42 314.2 644.04 382.93C633.89 362.04 599.83 291.91 590.3 272.28C619.1 212.96 686.3 74.57 717.98 9.32C720.69 3.74 726.34 0.220002 732.54 0.230002L829.82 0.380002Z"
					fill={theme.footerLogo || "#D8E6E6"}
				/>
				<path
					d="M544.74 587.41C517.43 643.64 440.23 802.57 418.34 847.62C415.97 852.5 409.01 852.5 406.64 847.62C357.56 746.55 54.56 122.6 0.850036 11.99C-1.83996 6.45 2.20004 0 8.36003 0H92.4C98.58 0 104.22 3.52 106.92 9.09C147.73 93.12 369.21 549.2 409.59 632.36C410.77 634.8 414.24 634.79 415.42 632.36L490.99 476.75C501.14 497.64 535.2 567.79 544.73 587.41H544.74Z"
					fill={theme.footerLogo || "#D8E6E6"}
				/>
				<path
					d="M1126.69 0C1132.85 0 1136.89 6.44 1134.2 11.98C1079.75 124.11 777.79 745.88 728.37 847.65C726 852.52 719.07 852.52 716.71 847.65C675.51 762.83 426.54 250.21 305.19 0.36L402.48 0.21C408.67 0.21 414.32 3.72 417.02 9.29C513.77 208.51 622.98 433.36 719.61 632.37C720.79 634.81 724.26 634.79 725.44 632.36C766.76 547.27 986.79 94.18 1028.12 9.08C1030.82 3.52 1036.46 0 1042.64 0H1126.69Z"
					fill={theme.footerLogo || "#D8E6E6"}
				/>
			</g>
			<defs>
				<clipPath id="clip0_0_92">
					<rect width="1135.05" height="851.3" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default WolfLogo;
