import React from "react";
import { useTheme } from "styled-components";

const LinkedIn = ({ className }) => {
	const theme = useTheme();
	return (
		<svg
			width="38"
			height="38"
			viewBox="0 0 38 38"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<g clipPath="url(#clip0_101_187)">
				<path
					d="M34.9161 0.114624H3.86389C2.22201 0.114624 0.890015 1.44662 0.890015 3.0885V34.1408C0.890015 35.7826 2.22201 37.1146 3.86389 37.1146H34.9161C36.558 37.1146 37.89 35.7826 37.89 34.1408V3.0885C37.89 1.44662 36.558 0.114624 34.9161 0.114624ZM34.9161 34.1546C14.2054 34.15 3.85001 34.1454 3.85001 34.1408C3.85464 13.43 3.85926 3.07462 3.86389 3.07462C24.5746 3.07925 34.93 3.08387 34.93 3.0885C34.9254 23.7992 34.9208 34.1546 34.9161 34.1546ZM6.37527 13.985H11.8651V31.6432H6.37527V13.985ZM9.12251 11.5707C10.8754 11.5707 12.3045 10.1462 12.3045 8.38875C12.3045 7.97088 12.2222 7.55711 12.0623 7.17105C11.9024 6.78499 11.668 6.43421 11.3725 6.13874C11.0771 5.84326 10.7263 5.60887 10.3402 5.44896C9.95415 5.28905 9.54038 5.20675 9.12251 5.20675C8.70465 5.20675 8.29087 5.28905 7.90482 5.44896C7.51876 5.60887 7.16798 5.84326 6.8725 6.13874C6.57702 6.43421 6.34264 6.78499 6.18273 7.17105C6.02282 7.55711 5.94051 7.97088 5.94051 8.38875C5.93589 10.1462 7.36039 11.5707 9.12251 11.5707ZM20.7914 22.9066C20.7914 20.6034 21.2308 18.3741 24.0844 18.3741C26.8964 18.3741 26.938 21.0057 26.938 23.0546V31.6432H32.4233V21.9585C32.4233 17.204 31.3965 13.5456 25.8419 13.5456C23.1733 13.5456 21.3834 15.0117 20.648 16.3993H20.574V13.985H15.3061V31.6432H20.7914V22.9066Z"
					fill={theme.footerIcon || "#fff"}
				/>
			</g>
			<defs>
				<clipPath id="clip0_101_187">
					<rect
						width="37"
						height="37"
						fill={theme.footerIcon || "#fff"}
						transform="translate(0.890015 0.114624)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default LinkedIn;
