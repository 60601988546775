import styled from "styled-components";

export const StyledHeader = styled.section`
	scroll-margin-top: 8rem;

	h1 {
		font-weight: 600;
		color: ${(props) => props.theme.headingTextColor};
		font-size: 2rem;

		@media screen and (max-width: 768px) {
			font-size: 1.75rem;
		}
	}
`;
