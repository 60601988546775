import React from "react";
import { useTheme } from "styled-components";

const Email = ({ className }) => {
	const theme = useTheme();
	return (
		<svg
			width="36"
			height="31"
			viewBox="0 0 36 31"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<path
				d="M31.0682 1.83838H5.29548C3.60097 1.83838 2.22729 3.34942 2.22729 5.21338V25.4634C2.22729 27.3273 3.60097 28.8384 5.29548 28.8384H31.0682C32.7627 28.8384 34.1364 27.3273 34.1364 25.4634V5.21338C34.1364 3.34942 32.7627 1.83838 31.0682 1.83838Z"
				stroke={theme.footerIcon || "#fff"}
				strokeWidth="3"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M7.13635 7.23828L18.1818 16.6883L29.2273 7.23828"
				stroke={theme.footerIcon || "#fff"}
				strokeWidth="3"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default Email;
