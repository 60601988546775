export const experience = [
	{
		id: 1,
		title: "Frontend Developer",
		company: "CodeForté Systems",
		date: "March 2023 - Current",
		description: [
			{
				id: 1,
				description: "ReactJs",
			},
			{
				id: 2,
				description: "React Redux",
			},
			{
				id: 3,
				description: "Jira",
			},
			{
				id: 4,
				description: "Figma",
			},
			{
				id: 5,
				description: "Contentful CMS",
			},
			{
				id: 6,
				description: "Styled Components",
			},
			{
				id: 7,
				description: "Material UI",
			},
			{
				id: "8",
				description: "Octopus Deploy",
			},
			{
				id: 9,
				description: "Microservices",
			},
		],
	},
	{
		id: 2,
		title: "Frontend Developer",
		company: "Analytix Engine",
		date: "July 2022 - March 2023",
		description: [
			{
				id: 1,
				description: "ReactJs",
			},
			{
				id: 2,
				description: "React Redux",
			},
			{
				id: 3,
				description: "Asset research",
			},
		],
	},
	{
		id: 3,
		title: "IT Graduate",
		company: "Cipla South Africa",
		date: "March 2020 - July 2022",
		description: [
			{
				id: 1,
				description: "Desktop Support",
			},
			{
				id: 2,
				description: "Asset Management",
			},
			{
				id: 3,
				description: "Machine Deployment",
			},
			{
				id: 4,
				description: "Server Management",
			},
			{
				id: 5,
				description: "Customer Support",
			},
		],
	},
];
