const initialState = {
	cookiesTracking: false,
};

/**
 * @method cookiesReducer
 */
export default function cookiesReducer(state = initialState, action) {
	switch (action.type) {
		case "SET_COOKIES_TRACKING":
			return {
				...state,
				cookiesTracking: action.payload,
			};
		default:
			return state;
	}
}
