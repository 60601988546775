import React, { useState, useRef, useEffect } from "react";
import { StyledNav, StyledNavbar, StyledMobileNav } from "./styledComponents";
import Wolf from "../../assets/images/Wolf";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import WbSunnyRoundedIcon from "@mui/icons-material/WbSunnyRounded";
import { useDispatch, useSelector } from "react-redux";
import { setDarkTheme, setLightTheme } from "../../actions/themeActions";
import NightsStayRoundedIcon from "@mui/icons-material/NightsStayRounded";

export default function Navbar() {
	const footerRef = useRef(null);
	const mobileNavRef = useRef(null);
	const dispatch = useDispatch();
	const currentTheme = useSelector((state) => state.theme.style);

	const [open, setOpen] = useState(false);

	const returnHome = () => {
		return (window.location.href = "/");
	};

	useEffect(() => {
		const hash = window.location.hash.substring(1);

		if (hash) {
			const element = document.getElementById(hash);

			if (element) {
				element.scrollIntoView({ behavior: "smooth" });
			}
		}
	}, []);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				(mobileNavRef.current &&
					!mobileNavRef.current.contains(event.target) &&
					!event.target.classList.contains("menu-icon")) ||
				event.target.classList.contains("")
			) {
				setOpen(false);
			}
		};

		document.addEventListener("click", handleClickOutside);
		document.addEventListener("scroll", () => setOpen(false));

		return () => {
			document.removeEventListener("click", handleClickOutside);
			document.removeEventListener("scroll", () => setOpen(false));
		};
	}, [mobileNavRef]);

	const scrollToFooter = () => {
		footerRef.current = document.querySelector("#main-footer");

		if (footerRef.current) {
			footerRef.current.scrollIntoView({ behavior: "smooth" });
		}
	};

	const changeTheme = () => {
		switch (currentTheme) {
			case "light":
				dispatch(setDarkTheme());
				localStorage.setItem("theme", "dark", { expires: 365 });
				break;
			default:
				dispatch(setLightTheme());
				localStorage.setItem("theme", "light", { expires: 365 });
				break;
		}
	};

	const navLinks = [
		{ text: "Home", href: "/#" },
		{ text: "About", href: "/#about" },
		{
			text: "Experience",
			href: "/#experience",
		},
		{ text: "Skills", href: "/#skills" },
		{ text: "Gallery", href: "/gallery" },
	];

	return (
		<>
			<StyledNavbar id="#" className="">
				<StyledNav className="backdrop-blur-md nav" id="navbar">
					<div className="logo" onClick={returnHome}>
						<Wolf className={"menu-logo"} />
					</div>
					<div className="links">
						{navLinks?.map((link, index) => (
							<a
								href={link.href}
								key={`${link.name}-${index}`}
								className="link"
								onClick={() => setOpen(false)}
							>
								{link.text}
							</a>
						))}
					</div>
					<div className="nav-right">
						<button className="theme-switcher" onClick={changeTheme}>
							{currentTheme === "dark" ? (
								<WbSunnyRoundedIcon />
							) : (
								<NightsStayRoundedIcon />
							)}
						</button>
						<button className="cta-button" onClick={scrollToFooter}>
							Contact
						</button>
					</div>
					{open ? (
						<CloseRoundedIcon
							className={`icon close-icon hamburger`}
							fontSize="large"
							onClick={() => setOpen(false)}
						/>
					) : (
						<MenuRoundedIcon
							className={`icon menu-icon hamburger`}
							fontSize="large"
							onClick={() => setOpen(true)}
						/>
					)}
				</StyledNav>
				<StyledMobileNav
					ref={mobileNavRef}
					className={`mobile-nav ${open ? "open" : "closed"}`}
				>
					<div className="links">
						{navLinks?.map((link, index) => (
							<a
								href={link.href}
								key={`${link.name}-${index}`}
								className="link"
								onClick={() => setOpen(false)}
							>
								{link.text}
							</a>
						))}
					</div>
					<button className="contact-button" onClick={() => scrollToFooter()}>
						Contact
					</button>
				</StyledMobileNav>
			</StyledNavbar>
		</>
	);
}
