import { combineReducers } from "redux";
import cmsReducer from "./cmsReducer";
import dialogReducer from "./dialogReducer";
import mobileNavReducer from "./mobileNavReducer";
import themeReducer from "./themeReducer";
import logoReducer from "./logoReducer";
import cookiesReducer from "./cookiesReducer";

const allReducers = combineReducers({
	cms: cmsReducer,
	dialog: dialogReducer,
	mobileNav: mobileNavReducer,
	theme: themeReducer,
	logos: logoReducer,
	cookies: cookiesReducer,
});

export default allReducers;
