import React from "react";
import { useTheme } from "styled-components";

const CSS = ({ className }) => {
	const theme = useTheme();
	return (
		<svg
			width="51"
			height="49"
			viewBox="0 0 51 49"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<g clipPath="url(#clip0_0_11)">
				<path
					d="M17.52 21.8799L17.22 18.5699H33.77L33.17 25.2599L33.09 26.1499L32.26 35.3999L25.49 37.2799L18.72 35.3999L18.26 30.2199H21.58L21.81 32.8499L25.49 33.8399L29.18 32.8499L29.56 28.5599H18.11L17.82 25.2599H29.85L30.15 21.8799H17.52Z"
					fill="url(#paint0_linear_0_11)"
				/>
				<path
					d="M41.64 0H9.36C4.47 0 0.5 3.96 0.5 8.86V39.89C0.5 44.78 4.47 48.75 9.36 48.75H41.64C46.53 48.75 50.5 44.78 50.5 39.89V8.86C50.5 3.96 46.53 0 41.64 0ZM28.12 9.32H30.45V9.02H28.12V5.75H32.12V7.21H29.79V7.5H32.12V10.84H28.12V9.32ZM23.32 9.32H25.65V9.02H23.32V5.75H27.32V7.21H25V7.5H27.32V10.84H23.32V9.32ZM18.52 5.75H22.52V7.42H20.19V9.1H22.52V10.77H18.52V5.75ZM36.29 40L25.49 42.99L14.71 40L11.54 13.07H39.47L36.29 40Z"
					fill="url(#paint1_linear_0_11)"
				/>
			</g>
			<defs>
				<linearGradient
					id="paint0_linear_0_11"
					x1="17.06"
					y1="33.4299"
					x2="34.18"
					y2="19.0599"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor={`${theme.iconGradientStop || "#0067C4"}`} />
					<stop
						offset="1"
						stopColor={`${theme.iconGradientStopOffset || "#96CEFF"}`}
					/>
				</linearGradient>
				<linearGradient
					id="paint1_linear_0_11"
					x1="1.6"
					y1="44.43"
					x2="49.4"
					y2="4.32"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor={`${theme.iconGradientStop || "#0067C4"}`} />
					<stop
						offset="1"
						stopColor={`${theme.iconGradientStopOffset || "#96CEFF"}`}
					/>
				</linearGradient>
				<clipPath id="clip0_0_11">
					<rect
						width="50"
						height="48.75"
						fill="white"
						transform="translate(0.5)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default CSS;
