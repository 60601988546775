import React from "react";
import { useTheme } from "styled-components";

const JS = ({ className }) => {
	const theme = useTheme();
	return (
		<svg
			width="51"
			height="49"
			viewBox="0 0 51 49"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<g clipPath="url(#clip0_0_26)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M50.17 6.47C49.13 2.74 45.7 0 41.64 0H9.36C5.3 0 1.87 2.74 0.83 6.47C0.61 7.23 0.5 8.03 0.5 8.86V39.89C0.5 40.72 0.61 41.52 0.83 42.28C1.87 46.01 5.3 48.75 9.36 48.75H41.64C45.7 48.75 49.13 46.01 50.17 42.28C50.39 41.52 50.5 40.72 50.5 39.89V8.86C50.5 8.03 50.39 7.23 50.17 6.47ZM27.7 38.19C27.7 42.99 24.88 45.17 20.77 45.17C17.06 45.17 14.91 43.25 13.82 40.94L17.59 38.65C18.32 39.95 18.99 41.04 20.58 41.04C22.17 41.04 23.06 40.44 23.06 38.13V22.36H27.7V38.19ZM38.65 45.18C34.35 45.18 31.57 43.13 30.2 40.44L33.97 38.25C34.97 39.88 36.26 41.07 38.55 41.07C40.47 41.07 41.69 40.11 41.69 38.78C41.69 37.19 40.43 36.63 38.32 35.7L37.16 35.2C33.81 33.78 31.59 31.99 31.59 28.22C31.59 24.74 34.24 22.09 38.38 22.09C41.33 22.09 43.45 23.12 44.98 25.81L41.37 28.12C40.57 26.7 39.71 26.14 38.38 26.14C37.05 26.14 36.16 27 36.16 28.12C36.16 29.52 37.02 30.08 39.01 30.94L40.17 31.44C44.11 33.13 46.34 34.85 46.34 38.72C46.34 42.9 43.06 45.18 38.65 45.18Z"
					fill="url(#paint0_linear_0_26)"
				/>
			</g>
			<defs>
				<linearGradient
					id="paint0_linear_0_26"
					x1="1.6"
					y1="44.43"
					x2="49.4"
					y2="4.32"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor={`${theme.iconGradientStop || "#0067C4"}`} />
					<stop
						offset="1"
						stopColor={`${theme.iconGradientStopOffset || "#96CEFF"}`}
					/>
				</linearGradient>
				<clipPath id="clip0_0_26">
					<rect
						width="50"
						height="48.75"
						fill="white"
						transform="translate(0.5)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default JS;
