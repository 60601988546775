import React, { useState, useEffect } from "react";
import { StyledBackToTop } from "./styled";
import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";
import Button from "../button";

export default function BackToTop() {
	const [showButton, setShowButton] = useState(false);

	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 1000) {
				// Change this value to set the scroll position
				setShowButton(true);
			} else {
				setShowButton(false);
			}
		};

		handleScroll();

		window.addEventListener("scroll", handleScroll);

		// Cleanup the event listener on component unmount
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	return (
		<StyledBackToTop>
			<button
				className={`back-to-top-button ${showButton ? "appear" : "disappear"}`}
				onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
			>
				<ArrowUpwardRoundedIcon className="back-to-top" />
			</button>
		</StyledBackToTop>
	);
}
