import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    * {
        font-family: "Poppins", "Montserrat", sans-serif !important;
    }

    body {
        margin: 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background-color: ${(props) => props.theme.backgroundColor};
        background-size: 250% 250%;
        background-repeat: repeat;
        animation: gradient 10s ease-in-out infinite; 
        overflow-x: hidden;
    
        background-image: ${(props) => props.theme.bodyBackground};
    }

    .progress-bar-container {
        width: 100%;
        height: 20px;
        background-color: #f0f0f0;
        border-radius: 4px;
        margin-top: 10px;
    }

    .progress-bar {
        height: 100%;
        background-color: #007bff;
        border-radius: 4px;
        transition: width 0.3s ease;
    }

    @keyframes popIn {
        0% {
            transform: scale(0);
        }
        100% {
            transform: scale(1);
        }
    }

    @keyframes popOut {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(0);
        }
    }

    .appear {
        animation: popIn 0.5s ease-in-out;
    }

    .disappear {
        animation: popOut 0.5s ease-in-out;
        visibility: hidden;
        width: 0 !important;
        height: 0 !important;
    }
`;
